import React from 'react';
import styles from './desktopHeader.module.scss';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyWrappers from 'wrappers/ComponentWrapper';
import SimLogo from '../simLogo'
import Timer from '../timerComponent'
import CostMetricsComponent from '../costMetricsComponent'
import GameMetricsGraphComponent from '../gameMetricsGraphComponent'
import GameMetricsValueComponent from '../gameMetricsValueComponent'
import LogoutComponent from '../logoutComponent'
import BudgetIcon from 'images/budget-header-icon.svg'
import DayIcon from 'images/day-icon.svg'

const DesktopHeader = (props) => {
    const myStyles = getSkin(props.skinGuide);
    let { isIntro, uiState, overallMetrics } = props;
    let daysBarValues,
        budgetBarValues,
        happinessBarValues,
        revenueBarValues,
        satisfactionBarValues,
        engagementBarValues,
        moralBarValues;
    if (!isIntro) {
        const currentDay =
            props.introData["initialDuration"] -
            overallMetrics["duration"]["metricValue"];
        daysBarValues = {
            icon: DayIcon,
            header: `${props.getLabel("label_metric_duration")}`,
            toolTip: `${props.getLabel("label_tooltip_days_desc")}`,
            value: `${currentDay} / ${props.introData["initialDuration"]}`,
            offset: overallMetrics.duration.offset,
            currentDay
        };
        budgetBarValues = {
            icon: BudgetIcon,
            header: `${props.getLabel("label_metric_budget")}`,
            toolTip: `${props.getLabel("label_tooltip_budget_desc")}`,
            value: `$${(overallMetrics["budget"]["metricValue"]).toLocaleString('en-US')}`,
            offset: overallMetrics.budget.offset
        };
        happinessBarValues = {
            name: `${props.getLabel("label_metric_happiness")}`,
            toolTip: `${props.getLabel("label_tooltip_happiness_desc")}`,
            value: overallMetrics["happiness"]["metricValue"].toFixed(1),
            offset: overallMetrics.happiness.offset.toFixed(1)
        };
        revenueBarValues = {
            name: `${props.getLabel("label_metric_revenue")}`,
            toolTip: `${props.getLabel("label_tooltip_revenue_desc")}`,
            value: overallMetrics["revenue"]["metricValue"],
            offset: overallMetrics.revenue.offset.toFixed(1)
        };
        satisfactionBarValues = {
            name: `${props.getLabel("label_metric_satisfaction")}`,
            toolTip: `${props.getLabel("label_tooltip_satisfaction_desc")}`,
            value: overallMetrics["satisfaction"]["metricValue"].toFixed(1)
        };
        engagementBarValues = {
            name: `${props.getLabel("label_metric_engagement")}`,
            toolTip: `${props.getLabel("label_tooltip_engagement_desc")}`,
            value: overallMetrics["engagement"]["metricValue"].toFixed(1)
        };
        moralBarValues = {
            name: `${props.getLabel("label_metric_motivation")}`,
            toolTip: `${props.getLabel("label_tooltip_morale_desc")}`,
            value: overallMetrics["motivation"]["metricValue"].toFixed(1)
        };
    }

    let { isActionsTutorialRunning } = props.tutorial;

    return (
        <div
            styleName="container"
            className={`${css(myStyles.headerContainer)} zoom-page`}
            style={isActionsTutorialRunning ? { zIndex: '10000' } : {}}
        >
            <div styleName='icon'>
                <SimLogo introData={props.introData} />
                {!isIntro && <Timer userInfo={props.userInfo} firebaseConfig={props.firebaseConfig} />}
            </div>
            {!isIntro && (
                <div styleName='metrics'>
                    <div styleName='cost-metrics'>
                        <CostMetricsComponent
                            {...daysBarValues}
                            uiState={uiState}
                        />
                        <CostMetricsComponent
                            {...budgetBarValues}
                            uiState={uiState}
                        />
                    </div>
                    <div styleName='vr-line' className={css(myStyles.vrLine)} />
                    <div styleName='game-metrics'>
                        <span styleName='tutorial-step-3' id='tutorial-step-3'>
                            <GameMetricsGraphComponent
                                {...happinessBarValues}
                                width='180px'
                                uiState={uiState}
                            />
                            {/* <GameMetricsValueComponent {...satisfactionBarValues} />
                            <GameMetricsValueComponent {...engagementBarValues} />
                            <GameMetricsValueComponent {...moralBarValues} /> */}
                        </span>
                        <span id='tutorial-step-4' style={{ height: '100%' }}>
                            <GameMetricsGraphComponent
                                {...revenueBarValues}
                                {...props.introData}
                                width='180px'
                                isRevenue={true}
                                uiState={uiState}
                            />
                        </span>
                    </div>
                </div>
            )}
            <LogoutComponent userInfo={props.userInfo} tutorial={props.tutorial} setUiState={props.setUiState} />
        </div>
    );
};

export default applyWrappers(DesktopHeader, styles);
