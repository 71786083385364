import consts from 'constants/url';
import netpack from 'utils/netpack';
import {checkIfPresent} from 'utils/utilFunctions';
import { setUiState } from '../uiState/actionCreators';
import analyticsUtil from 'utils/segmentUtil';

export const fetchReportPdf = () => (dispatch) => netpack(consts.USER_REPORT_PDF_URL)
    .get()
    .then(response => {

        //Segment integartion
        analyticsUtil.track("DOWNLOAD_USER_REPORT_COMPLETED",{url: response.url});
        
        if(checkIfPresent(response.url)) {
            dispatch(setUiState({
                reportUrl: response.url
            }));
            var link = document.createElement('a');
            link.href = response.url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        dispatch(setUiState({
            isReportDownloading: false
        }));
    });